import React from "react";

const FluchBox1 = ({ top, theme, element, size, click }) => {
  return (
    <button
      onClick={click || null}
      className={`cursor-pointer group relative border border-primary text-[10px] font-bold ${
        theme ? "text-primary hover:bg-transparent border-white hover:text-white" : "text-primary hover:text-white hover:bg-primary border-primary"
      } duration-500 px-[28px] py-[15px]`}
    >
      <div className="z-20 absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col">
        <span
          className={`${
            top ? top : "bg-slate-800"
          } group-hover:translate-x-[105%] flex-1 duration-500`}
        />
        <span
          className={`${
            top ? top : "bg-slate-800"
          } group-hover:-translate-x-[105%] flex-1 duration-500`}
        />
      </div>
      <span
        className={`relative z-50  ${size ? `text-[16px]` : `text-[11px]`} `}
      >
        {element}
      </span>
    </button>
  );
};

export default FluchBox1;
