import React from "react";
import CustomInput from "./CustomInput";
import FluchBox1 from "../ui/FluchBox1";
import UseSendEmail from "../../hooks/UseSendEmail";
import { useFormik } from "formik";
import { CircularProgress } from "@mui/material";
import * as Yup from "yup";

const ContactForm = () => {
  const { sendEmail, isLoading } = UseSendEmail();
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      contenu: "",
      numero: "",
    },
    onSubmit: (values) => {
      sendEmail(values);
      formik.resetForm();
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Veuillez renseigner un email valide"),
      contenu: Yup.string().required("Veuillez renseigner votre message"),
    }),
  });
  return (
    <form
      className="w-full flex flex-col items-end gap-[20px]"
      onSubmit={formik.handleSubmit}
    >
      <div className="w-full grid gap-[30px] grid-cols-1 lg:grid-cols-2">
        <CustomInput
          placeholder="Nom complet"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <CustomInput
          error={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : null
          }
          onBlur={formik.handleBlur}
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <div className="col-span-2">
          <CustomInput
            placeholder="Objet de votre email"
            name="numero"
            value={formik.values.numero}
            onChange={formik.handleChange}
          />
        </div>
        {/* <CustomInput placeholder="Site web" /> */}
        <CustomInput
          error={
            formik.errors.contenu && formik.touched.contenu
              ? formik.errors.contenu
              : null
          }
          onBlur={formik.handleBlur}
          value={formik.values.contenu}
          onChange={formik.handleChange}
          name="contenu"
          id=""
          rows="5"
          area="true"
          placeholder="Comment pouvons nous vous aidez ?"
        />
      </div>
      <FluchBox1
        type="submit"
        top={"bg-white"}
        element={
          <span className="text-[13px] font-normal flex gap-2 items-center">
            ENVOYER LE MESSAGE
            {isLoading && <CircularProgress color="inherit" size={20} />}
          </span>
        }
      />
    </form>
  );
};

export default ContactForm;
