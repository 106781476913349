import React from "react";
import AboutOffer from "./slices/AboutOffer";
// import AboutWorker from "./slices/AboutWorker";
import Testimonials from "../home/slices/Testimonials";

const About = () => {
  return (
    <div>
      <AboutOffer />
      {/* <AboutWorker /> */}
      <Testimonials />
    </div>
  );
};

export default About;
