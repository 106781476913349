import React, { useContext } from "react";
import { ThemeContext } from "../../../theme/ThemeContext";
import { motion } from "framer-motion";

const Stepper = ({ step, max }) => {
  const { bg } = useContext(ThemeContext);
  return (
    <div>
      <p className="mb-2">
        Etape {step + 1} sur {max}
      </p>
      <div className="h-4 rounded-full overflow-hidden relative bg-gray-100 w-full mb-10">
        <motion.div
          animate={{ width: `${(step + 1) * 20}%` }}
          className={`absolute left-0 bottom-0 top-0 ${bg}`}
        />
        {/* <progress max="5" is="2" className="rounded-full" /> */}
      </div>
    </div>
  );
};

export default Stepper;
