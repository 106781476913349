import React from "react";
import NavBar from "../../components/ui/NavBar";
import ImageCarousel from "../../components/ui/ImageCarousel";

const HomeHeader = () => {
  return (
    <div className="h-[60vh] lg:h-screen bg-secondary relative">
      <NavBar />
      <ImageCarousel/>
    </div>
  );
};

export default HomeHeader;
