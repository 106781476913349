import { useContext } from "react";
import { ThemeContext } from "../../../../theme/ThemeContext";
import data from "../../../../contants/texts.json";
import FluchBox from "../../../../components/ui/FluchBox";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";

const Coyriht = () => {
  const texts = data.Copyright;
  const { ct, text } = useContext(ThemeContext);
  return (
    <div className={`bg-secondary py-[40px]`}>
      <div
        className={`${ct} flex justify-between flex-col lg:flex-row gap-x-16 gap-y-6 items-center uppercase text-[11px] text-white/80 font-normal`}
      >
        <p className="space-x-1">
          <span>{texts.left}</span>
          <span className={`${text}`}>{texts.particule}</span>
        </p>
        <div className="flex space-x-1">
          {/* <FluchBox prop={"p-2 rounded"} element={<FaTwitter />} /> */}
          <a
            href="https://www.facebook.com/profile.php?id=100091954818565"
            target="_blank"
            rel="noreferrer"
          >
            <FluchBox prop={"p-2 rounded"} element={<FaFacebookF />} />
          </a>
          {/* <FluchBox prop={"p-2 rounded"} element={<FaGoogle />} /> */}
          <a
            href="https://instagram.com/djibservice?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noreferrer"
          >
            <FluchBox prop={"p-2 rounded"} element={<FaInstagram />} />
          </a>
          <a
            href="https://www.youtube.com/@DjibService"
            target="_blank"
            rel="noreferrer"
          >
            <FluchBox prop={"p-2 rounded"} element={<FaYoutube />} />
          </a>
        </div>
        <p className="space-x-1">
          <span>{texts.right}</span>
          <span className={`${text}`}>{texts.particule}</span>
        </p>
      </div>
    </div>
  );
};

export default Coyriht;
