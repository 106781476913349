import React from 'react'
import Contactblock1 from './slices/Contactblock1'
import Contactblock2 from './slices/Contactblock2'
import Map from './slices/Map'

const Contact = () => {
  return (
    <div>
      <Contactblock1 />
      <Contactblock2 />
      <Map />
    </div>
  )
}

export default Contact