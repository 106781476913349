import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../contants";

const UseSendDevis = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [data, setdata] = useState(false);

  const sendDevis = (data) => {
    setisLoading(true);
    axios
      .post(`${BASE_URL}/api/devis/devisMessage`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setdata(response);
        setisSuccess(true);
        localStorage.clear();
        setisLoading(false);
      })
      .catch((error) => {
        console.log(error);

        seterror(error);
        setisLoading(false);
      });
  };

  return { data, isLoading, error, sendDevis, isSuccess };
};

export default UseSendDevis;
