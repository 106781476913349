import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import FluchBox from "../../ui/FluchBox";
import { ThemeContext } from "../../../theme/ThemeContext";
import { form5Schema } from "../validations/validateSchema";
import CustomRadio from "../../ui/CustomRadio";
import CustomTextArea from "../CustomTextArea";
import { MdClose } from "react-icons/md";
import { LuFileBox } from "react-icons/lu";
import UseSendDevis from "../../../hooks/UseSendDevis";
import { CircularProgress } from "@mui/material";

const Form5 = ({ back, reset }) => {
  const { sendDevis, isLoading, isSuccess } = UseSendDevis();
  const previmg = JSON.parse(localStorage.getItem("images"));
  const [images, setimages] = useState(previmg?.images || []);
  const [image, setimage] = useState(previmg?.image || []);
  const { bg } = useContext(ThemeContext);
  const localval = JSON.parse(localStorage.getItem("form5"));
  const initialValues = {
    infosup: "",
    convtarif: "",
    montantconvtarif: "",
    acompte: "",
    montantacompte: "",
    range: 0,
  };
  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleImageChange = async (e) => {
    Object.keys(e.target.files).forEach(async (key) => {
      const img = e.target.files[key];
      setimage((state) => [...state, img]);
      const validImg = await convertFileToBase64(img);
      setimages((state) => [
        ...state,
        { name: img.name, photo: validImg, size: Math.round(img.size / 1024) },
      ]);
    });
  };

  const handleSendDevis = async (last) => {
    const finalData = JSON.parse(localStorage.getItem("form"));
    const formData = new FormData();

    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };

    for (let i = 0; i < image.length; i++) {
      formData.append("photoMeubles", image[i]);
    }
    formData.append("nom", finalData.form1.nom);
    formData.append("prenom", finalData.form1.prenom);
    formData.append("email", finalData.form1.email);
    formData.append("villeDepart", finalData.form1.ville_de_depart);
    formData.append("villeArrived", finalData.form1.ville_d_arrivee);
    formData.append("service", finalData.form1.service);
    formData.append(
      "datePrestation",
      formatDate(finalData.form1.date_prestation)
    );

    formData.append("etageAuDepart", finalData.form2.etage_depar);
    formData.append("tailleAscenceurDepart", finalData.form2.taille_ascenseur);
    formData.append("placeParkingDepart", finalData.form2.place_parking);
    formData.append(
      "reserverPlaceMairieDepart",
      finalData.form2.place_parking === "Non" ? finalData.form2.isreserved : ""
    );
    formData.append(
      "distanceLieuCamionDepart",
      finalData.form2.distance_camion
    );

    formData.append("etageArrived", finalData.form3.etage_depar);
    formData.append("tailleAscenceurArrived", finalData.form3.taille_ascenseur);
    formData.append("placeParkingArrived", finalData.form3.place_parking);
    formData.append(
      "reserverPlaceMairieArrived",
      finalData.form3.place_parking === "Non" ? finalData.form3.isreserved : ""
    );
    formData.append(
      "distanceLieuCamionArrived",
      finalData.form3.distance_camion
    );

    formData.append("listeMeubles", finalData.form4.liste_demen);
    formData.append("meubleDemonterRemonter", finalData.form4.meuble_dem_rem);
    formData.append(
      "precisionMeubleDemonterRemonter",
      finalData.form4.meuble_dem_rem === "Oui" ? finalData.form4.precision : ""
    );
    formData.append("meubleEnterAscenceurDepart", finalData.form4.dep);
    formData.append(
      "listeMeublesEnterAscenceurDepart",
      finalData.form4.dep === "Non" ? finalData.form4.rentrepasdep : ""
    );
    formData.append("meubleEnterAscenceurArriver", finalData.form4.arr);
    formData.append(
      "listeMeubleEnterAscenceurArriver",
      finalData.form4.arr === "Non" ? finalData.form4.rentrepasarr : ""
    );

    formData.append("distanceEntreAdresse", last.range);
    formData.append("tarifConvenu", last.convtarif);
    formData.append(
      "montantTarifConvenu",
      last.convtarif === "Oui" ? last.montantconvtarif : ""
    );
    formData.append("vercerAcompte", last.acompte);
    formData.append(
      "montantVercerAcompte",
      last.acompte === "Oui" ? last.montantacompte : ""
    );
    formData.append("infosSupplementaire", last.infosup);

    try {
      sendDevis(formData);
    } catch (error) {
      console.log('Une erreur est survenue !');
      
    }
  };

  const formik = useFormik({
    initialValues: localval || initialValues,
    onSubmit: (values) => {
      handleSendDevis(values);
    },
    enableReinitialize: true,
    validationSchema: form5Schema,
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4"
      >
        <div className="lg:col-span-2 flex flex-col gap-1">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <label className="font-medium text-[16px] text-primary">
              Distance en kilomètres entre les deux adresses
            </label>
            <p>Valeur sélectionnée : {formik.values.range}</p>
          </div>
          <input
            value={formik.values.range}
            name="range"
            min={"0"}
            max={"1000"}
            type="range"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`slider focus:outline-none outline-none bg-gray-300 border ring-1 ring-transparent ${
              (
                formik.errors.range && formik.touched.range
                  ? formik.errors.range
                  : null
              )
                ? "focus:ring-red-300"
                : "focus:ring-cyan-300"
            } rounded p-[12px] border-primary/20 hover:border-primary/80 duration-300`}
          />
          <p className="text-sm text-red-500">
            {formik.errors.range && formik.touched.range
              ? formik.errors.range
              : null}
          </p>
        </div>
        <div>
          <CustomRadio
            value={formik.values.convtarif}
            error={
              formik.errors.convtarif && formik.touched.convtarif
                ? formik.errors.convtarif
                : null
            }
            name="convtarif"
            formik={formik}
            data={["Oui", "Non"]}
            label={"Avons-nous déjà convenu d’un tarif ?"}
          />
          {formik.values.convtarif === "Oui" && (
            <CustomTextArea
              error={
                formik.errors.montantconvtarif &&
                formik.touched.montantconvtarif
                  ? formik.errors.montantconvtarif
                  : null
              }
              name="montantconvtarif"
              formik={formik}
              text={"Veuillez préciser le montant en euros (€)."}
            />
          )}
        </div>
        <div>
          <CustomRadio
            value={formik.values.acompte}
            error={
              formik.errors.acompte && formik.touched.acompte
                ? formik.errors.acompte
                : null
            }
            name="acompte"
            formik={formik}
            data={["Oui", "Non"]}
            label={"Avez-vous déjà versé un acompte ?"}
          />
          {formik.values.acompte === "Oui" && (
            <CustomTextArea
              error={
                formik.errors.montantacompte && formik.touched.montantacompte
                  ? formik.errors.montantacompte
                  : null
              }
              name="montantacompte"
              formik={formik}
              text={"Veuillez préciser le montant de l'acompte en euros (€)."}
            />
          )}
        </div>
        <div className="lg:col-span-2">
          <CustomTextArea
            value={formik.values.infosup}
            error={
              formik.errors.infosup && formik.touched.infosup
                ? formik.errors.infosup
                : null
            }
            name="infosup"
            formik={formik}
            text={"Avez-vous des informations supplémentaires à communiquer ?"}
          />
        </div>
        <div className="lg:col-span-2 w-full px-4 py-6 border border-primary/20 rounded hover:border-primary/60">
          <label className="cursor-pointer">
            <div className="flex flex-col gap-3 items-center leading-4">
              <span className="text-3xl text-primary/80 font-light">
                <LuFileBox />
              </span>
              <p className="text-primary/80">
                Cliquez ou faites glisser les fichiers vers cette zone pour les
                télécharger.
              </p>
              <p className="text-primary/80">
                Vous pouvez télécharger jusqu'à 15 fichiers.
              </p>
            </div>
            <input
              type="file"
              name=""
              multiple
              id=""
              hidden
              onChange={handleImageChange}
            />
          </label>
          {images.length > 0 && (
            <div className="flex flex-wrap gap-4 mt-4 w-full">
              {images.map((el, key) => (
                <div
                  className="flex w-full mx-4 border-b border-b-primary/10 pb-3"
                  key={key}
                >
                  <div className="w-12 h-12 overflow-hidden">
                    <img
                      src={el.photo}
                      alt="upload"
                      className="object-cover w-full"
                    />
                  </div>
                  <div className="flex flex-col ml-4 gap-3 text-sm">
                    <p>{el.size} Kb</p>
                    <p>{el.name}</p>
                  </div>
                  <span
                    onClick={() => {
                      setimages((state) =>
                        state.filter((_, index) => index !== key)
                      );
                      setimage((state) =>
                        state.filter((_, index) => index !== key)
                      );
                    }}
                    className="cursor-pointer ml-auto flex justify-center items-center h-6 w-6 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
                  >
                    <MdClose />
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="lg:col-span-2 flex flex-col md:flex-row justify-start lg:justify-end gap-3 items-end">
          <FluchBox
            // top={bg}
            click={() => {
              localStorage.setItem("form5", JSON.stringify(formik.values));
              localStorage.setItem("images", JSON.stringify({ image, images }));
              back();
            }}
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={"PRECEDENT"}
          />
          <FluchBox
            top={bg}
            type="submit"
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={
              <span className="flex justify-center gap-2 items-center">
                ENVOYER{" "}
                {isLoading && <CircularProgress size={18} color="inherit" />}
              </span>
            }
          />
        </div>
      </form>
    </div>
  );
};

export default Form5;
