import React, { Suspense } from "react";
import Loader from "./Loader";

function Loadable(ImportedComponent) {
  return function LazyComponent(props) {
    return (
      <Suspense fallback={<Loader />}>
        <ImportedComponent {...props} />
      </Suspense>
    );
  };
}

export default Loadable;
