import React, { useContext } from "react";
import { ThemeContext } from "../../../theme/ThemeContext";
import { FaComments } from "react-icons/fa";
import ContactForm from "../../../components/forms/ContactForm";

const Contactblock1 = () => {
  const { ct, bg } = useContext(ThemeContext);
  return (
    <div className={`${ct} py-[100px]`}>
      <div className="flex flex-col gap-[85px] lg:flex-row">
        <div className="flex flex-col space-y-[50px] lg:max-w-[400px]">
          <div className={`${bg} gap-6 p-[30px] flex items-center text-white`}>
            <FaComments className="text-[70px]" />
            <h1 className="text-[25px] font-bold leading-7">
              ON SERA RAVIS DE VOUS AIDER
            </h1>
          </div>
          <p className="text-[13px] font-normal leading-5 text-justify">
            Vous avez besoin d'aide pour votre prochain déménagement dans la
            région de Lyon ? Notre équipe d'experts en déménagement est là pour
            vous épauler à chaque étape du processus.
          </p>
          <div className={`self-end flex gap-3 items-center text-slate-800`}>
            <div className="w-11 h-11 rounded-full overflow-hidden bg-[#F5F5F5]">
              <img
                src={require("../../../assets/djib.jpeg")}
                className="object-contain w-full h-full"
                alt=""
              />
            </div>
            <div className="flex flex-col items-end justify-end leading-5">
              <p className="mt-[2px] p-0 mb-0 text-[14px] uppercase font-bold text-slate-700">
                Djibril
              </p>
             
            </div>
          </div>
        </div>
        <div className="w-full">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contactblock1;
