import { lazy } from "react";

// project imports

import MainLayout from "../layout/MainLayout";
import Loadable from "../components/ui/Loadable";
import About from "../pages/about";
import Devis from "../pages/devis";
import Contact from "../pages/contact";

// page routing
const Home = Loadable(lazy(() => import("../pages/home")));
const Service = Loadable(lazy(() => import("../pages/services")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "accueil",
      element: <Home />,
    },
    {
      path: "services",
      element: <Service />,
    },
    {
      path: "a-propos",
      element: <About />,
    },
    {
      path: "devis",
      element: <Devis />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
  ],
};

export default MainRoutes;
