import React from "react";
import { useContext } from "react";
import { ThemeContext } from "../../../../theme/ThemeContext";
import data from "../../../../contants/texts.json";
import FootBlock from "../../../../components/ui/FootBlock";
import { Link } from "react-router-dom";
import AdressList from "../../../../components/ui/AdressList";

const FooterNavigation = () => {
  const texts = data.footerNav;
  const { ct, bg } = useContext(ThemeContext);

  return (
    <div className={`bg-primary py-[65px]`}>
      <div
        className={`${ct} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-x-12 gap-y-14`}
      >
        <FootBlock prop={"col-span-1 lg:col-span-2"} Label>
          <p className="text-[13px] text-gray-200">{texts[0].text}</p>
          <a
            href="https://www.google.com/localservices/prolist?g2lbs=AOHF13knQc87DNfET8yrioRApUVc64i7n1-8p96aOHR84tLICyBjI-6g6zlyOlF1ngUxHC9fpJC1o0KPY7hsdOicdYa_bRhFspQ1oMRgbSdRzeuDlTEcqsg%3D&hl=en-CM&gl=cm&cs=1&ssta=1&q=djib%20demenagement&oq=djib%20demenagement&src=2&spp=Cg0vZy8xMXZ5cTc2ZDZqOtgBV2g4UUFCQUJHQUFZQVNJUlpHcHBZaUJrWlcxbGJtRm5aVzFsYm5ReUFtWnlrZ0VPYlc5MmFXNW5YMk52YlhCaGJubWFBU05EYUZwRVUxVm9UazFIT1c1VE1GWktVVEJHYmxOVlVrVk9NRGsxVkd0YU0wVkJSYW9CUmdvS0wyMHZNRFF6Y25oNU1CQUJNaDhRQVNJYnhzRHprd09xdzlOOXVIZl9TUHNJdklrNFYxWGdwZ2RfaWtESE1oVVFBaUlSWkdwcFlpQmtaVzFsYm1GblpXMWxiblE9&serdesk=1&lrlstt=1719430897785&ved=2ahUKEwiJxJecgvqGAxUFU0EAHa4sDOYQvS56BAgaEAE&slp=MgBAAVIECAIgAGAAaAE%3D&scp=ChNnY2lkOm1vdmluZ19jb21wYW55EiAiCG1hcCBhcmVhKhQNDU-j-xWv66kBHd7-SCsl_ug_LRoRZGppYiBkZW1lbmFnZW1lbnQqEFJlbW92YWxzIGNvbXBhbnk%3D#ts=3"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className={`${bg} px-[28px] py-[15px] text-white text-[12px]`}
            >
              {texts[0].action}
            </button>
          </a>
        </FootBlock>
        <FootBlock label={texts[1].label}>
          <ul className="space-y-2.5 text-gray-200 text-[14px]">
            {texts[1].links.map((item, index) => {
              if (item.text === "A Propos") {
                return (
                  <li key={index}>
                    <a
                      href="https://www.google.com/localservices/prolist?g2lbs=AOHF13knQc87DNfET8yrioRApUVc64i7n1-8p96aOHR84tLICyBjI-6g6zlyOlF1ngUxHC9fpJC1o0KPY7hsdOicdYa_bRhFspQ1oMRgbSdRzeuDlTEcqsg%3D&hl=en-CM&gl=cm&cs=1&ssta=1&q=djib%20demenagement&oq=djib%20demenagement&src=2&spp=Cg0vZy8xMXZ5cTc2ZDZqOtgBV2g4UUFCQUJHQUFZQVNJUlpHcHBZaUJrWlcxbGJtRm5aVzFsYm5ReUFtWnlrZ0VPYlc5MmFXNW5YMk52YlhCaGJubWFBU05EYUZwRVUxVm9UazFIT1c1VE1GWktVVEJHYmxOVlVrVk9NRGsxVkd0YU0wVkJSYW9CUmdvS0wyMHZNRFF6Y25oNU1CQUJNaDhRQVNJYnhzRHprd09xdzlOOXVIZl9TUHNJdklrNFYxWGdwZ2RfaWtESE1oVVFBaUlSWkdwcFlpQmtaVzFsYm1GblpXMWxiblE9&serdesk=1&lrlstt=1719430897785&ved=2ahUKEwiJxJecgvqGAxUFU0EAHa4sDOYQvS56BAgaEAE&slp=MgBAAVIECAIgAGAAaAE%3D&scp=ChNnY2lkOm1vdmluZ19jb21wYW55EiAiCG1hcCBhcmVhKhQNDU-j-xWv66kBHd7-SCsl_ug_LRoRZGppYiBkZW1lbmFnZW1lbnQqEFJlbW92YWxzIGNvbXBhbnk%3D#ts=3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.text}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <Link to={item.path}>{item.text}</Link>
                  </li>
                );
              }
            })}
          </ul>
        </FootBlock>
        <FootBlock label={texts[2].label} prop={"col-span-1 lg:col-span-2"}>
          <AdressList data={texts[2].addresItem} />
        </FootBlock>
        <FootBlock label={texts[3].label} prop={"col-span-1 lg:col-span-2"}>
          <ul className="space-y-2.5 text-gray-200 text-[14px]">
            {[
              {
                text: "Instagram",
                link: "https://instagram.com/djibservice?igshid=YmMyMTA2M2Y=",
              },
              { text: "Youtube", link: "https://www.youtube.com/@DjibService" },
            ].map((el, key) => (
              <li key={key}>
                <a href={el.link} target="_blank" rel="noreferrer">
                  {el.text}
                </a>
              </li>
            ))}
          </ul>
        </FootBlock>
      </div>
    </div>
  );
};

export default FooterNavigation;
