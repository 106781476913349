import React from "react";
import { MoonLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="z-50 absolute top-0 bottom-0 left-0 right-0 w-full bg-white flex justify-center items-center">
      <MoonLoader
        color={"#00C0E2"}
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
