import { useFormik } from "formik";
import React, { useContext } from "react";
import FluchBox from "../../ui/FluchBox";
import { ThemeContext } from "../../../theme/ThemeContext";
import { form4Schema } from "../validations/validateSchema";
import CustomRadio from "../../ui/CustomRadio";
import CustomTextArea from "../CustomTextArea";

const Form4 = ({ next, back, add }) => {
  const { bg } = useContext(ThemeContext);
  const localval = JSON.parse(localStorage.getItem("form4"));
  const initialValues = {
    liste_demen: "",
    liste_rentre: "",
    meuble_dem_rem: "",
    dep: "",
    arr: "",
    precision: "",
    rentrepasdep: "",
    rentrepasarr: "",
  };
  const formik = useFormik({
    initialValues: localval || initialValues,
    onSubmit: (values) => {
      localStorage.setItem("form4", JSON.stringify(values));
      add({ form4: values });
      next();
    },
    enableReinitialize: true,
    validationSchema: form4Schema,
  });

  return (
    <div>
      <p className="mb-4 font-semibold text-primary text-xl">Vos meubles</p>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4"
      >
        <div className="lg:col-span-2">
          <CustomTextArea
            value={formik.values.liste_demen}
            error={
              formik.errors.liste_demen && formik.touched.liste_demen
                ? formik.errors.liste_demen
                : null
            }
            name="liste_demen"
            formik={formik}
            text={"Liste des meubles ou objets à déménager"}
          />
        </div>
        {/* <CustomTextArea
          error={
            formik.errors.liste_rentre && formik.touched.liste_rentre
              ? formik.errors.liste_rentre
              : null
          }
          name="liste_rentre"
          formik={formik}
          text={"Listez les meubles qui ne rentrent pas SVP"}
        /> */}
        <div>
          <CustomRadio
            value={formik.values.meuble_dem_rem}
            error={
              formik.errors.meuble_dem_rem && formik.touched.meuble_dem_rem
                ? formik.errors.meuble_dem_rem
                : null
            }
            name="meuble_dem_rem"
            formik={formik}
            data={["Oui", "Non"]}
            label={"Y aura-t-il des meubles à démonter et puis remonter ?"}
          />
          {formik.values.meuble_dem_rem === "Oui" && (
            <CustomTextArea
              value={formik.values.precision}
              error={
                formik.errors.precision && formik.touched.precision
                  ? formik.errors.precision
                  : null
              }
              name="precision"
              formik={formik}
              text={"Veuillez préciser SVP"}
            />
          )}
        </div>
        <div>
          <CustomRadio
            col
            value={formik.values.dep}
            error={
              formik.errors.dep && formik.touched.dep ? formik.errors.dep : null
            }
            name="dep"
            formik={formik}
            data={[
              "Oui",
              "Non",
              "Pas d’ascenseur",
              "On n'aura pas besoin de l’ascenseur car c'est au rez-de-chaussée",
            ]}
            label={"Au départ, tous les meubles rentreront dans l’ascenseur ?"}
          />
          {formik.values.dep === "Non" && (
            <CustomTextArea
              value={formik.values.rentrepasdep}
              error={
                formik.errors.rentrepasdep && formik.touched.rentrepasdep
                  ? formik.errors.rentrepasdep
                  : null
              }
              name="rentrepasdep"
              formik={formik}
              text={"Listez les meubles qui ne rentrent pas SVP"}
            />
          )}
        </div>
        <div>
          <CustomRadio
            col
            value={formik.values.arr}
            error={
              formik.errors.arr && formik.touched.arr ? formik.errors.arr : null
            }
            name="arr"
            formik={formik}
            data={[
              "Oui",
              "Non",
              "Pas d’ascenseur",
              "On n'aura pas besoin de l’ascenseur car c'est au rez-de-chaussée",
            ]}
            label={
              "A l’arrivée, tous les meubles rentreront dans l’ascenseur ?"
            }
          />
          {formik.values.arr === "Non" && (
            <CustomTextArea
              value={formik.values.rentrepasarr}
              error={
                formik.errors.rentrepasarr && formik.touched.rentrepasarr
                  ? formik.errors.rentrepasarr
                  : null
              }
              name="rentrepasarr"
              formik={formik}
              text={"Listez les meubles qui ne rentrent pas SVP"}
            />
          )}
        </div>
        <div className="lg:col-span-2 flex flex-col md:flex-row justify-start lg:justify-end gap-3 items-end">
          <FluchBox
            // top={bg}
            click={back}
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={"PRECEDENT"}
          />
          <FluchBox
            top={bg}
            type="submit"
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={"SUIVANT"}
          />
        </div>
      </form>
    </div>
  );
};

export default Form4;
