import React, { useContext } from "react";
import { ThemeContext } from "../../../theme/ThemeContext";

const AboutOffer = () => {
  const { ct, bg } = useContext(ThemeContext);
  return (
    <div className={`${ct} py-[100px]`}>
      <div className="flex flex-col items-center gap-[30px]">
        <div className="h-[350px] w-full overflow-hidden">
          <img
            src={
              "https://djib-demenagement.fr/wp-content/uploads/2023/07/WhatsApp-Image-2023-07-16-at-9.53.17-AM-1536x1277.jpeg"
            }
            alt=""
            className="object-cover "
          />
        </div>
        <h2 className="text-[20px] font-bold text-center lg:text-left text-primary">
          NOUS VOUS OFFRONS LA MEILLEUR EXPERIENCE
        </h2>
        <div className="space-y-1">
          <div
            className={`${bg} h-[2.5px] w-[250px] border-b border-blue-400`}
          />
          <div className={`${bg} h-[1px] w-[250px] border-b border-blue-400`} />
        </div>
        <p className="text-center leading-[22px] text-[13px] mb-[10px]">
          Nous sommes une entreprise spécialisée dans les services de
          déménagement, offrant également des prestations de nettoyage pour les
          anciens et nouveaux domiciles de nos clients. Notre équipe d’experts
          en manutention possède une solide expérience dans la gestion de biens
          variés. Nous nous engageons à fournir des services de déménagement de
          haute qualité, en assurant un soin optimal de vos biens. En plus du
          transport, nous proposons des services de démontage et remontage de
          meubles pour simplifier votre déménagement.
        </p>
      </div>
    </div>
  );
};

export default AboutOffer;
