import React, { useContext, useState } from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import FluchBox from "./FluchBox";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

const Carousel = [
  {
    img: "https://img.freepik.com/free-photo/close-up-delivery-person-with-parcel_23-2149095948.jpg?t=st=1717606987~exp=1717610587~hmac=62547a27a8c1bcaf3a8b28448a9909dfb7f0cbd58b110a289f4879251a0a05fa&w=900",
    title: "Déménagements experts",
    text: "Rapide, efficace et fiable",
  },
  {
    img: "https://img.freepik.com/free-photo/close-up-delivery-person-with-parcels_23-2149095947.jpg?w=1060&t=st=1717602996~exp=1717603596~hmac=fe3993c2c4a93d1b31aeb2e79071cd99bb787141aea958230b1fc0dbf605582b",
    title: "Emballage sécurisé",
    text: "Vos biens protégés",
  },
  {
    img: "https://img.freepik.com/free-photo/medium-shot-delivery-woman-holding-pack_23-2149103437.jpg?t=st=1717606264~exp=1717609864~hmac=c78cbbbc492f3e2b568c5cdf9cae6b35efc32011b9d05af5316f129f0128eb31&w=900",
    title: "Transport en camions",
    text: "Livrés en toute sécurité",
  },
  {
    img: "https://img.freepik.com/free-photo/close-up-delivery-man-with-tablet_23-2149103394.jpg?t=st=1717606300~exp=1717609900~hmac=ef200c2a79749974a6ecd0d051d570720838a3096873282ac7cd21f628d1181e&w=900",
    title: "Démontage & remontage",
    text: "Sans soucis",
  },
];

const ImageCarousel = () => {
  const [activeTab, setactiveTab] = useState(0);
  const carouselLength = Carousel.length;

  const handleNextItem = () => {
    if (activeTab === carouselLength - 1) {
      setactiveTab(0);
    } else {
      setactiveTab(activeTab + 1);
    }
  };
  const handleBackItem = () => {
    if (activeTab === 0) {
      setactiveTab(carouselLength - 1);
    } else {
      setactiveTab(activeTab - 1);
    }
  };
  const { text, bg } = useContext(ThemeContext);

  return (
    <div className="absolute h-full top-0 bottom-0 overflow-hidden right-0 left-0 z-20">
      <div className="w-full h-full relative">
        {Carousel.map((el, i) => (
          <motion.div
            key={i}
            className={`${
              activeTab === i ? "block" : "hidden"
            } h-full md:h-auto`}
          >
            <div
              className={`px-[40px] pt-16 lg:pt-0 lg:px-[110px] xl:px-[190px] h-full flex items-center justify-end text-right left-0 uppercase absolute w-full text-white z-40`}
            >
              <AnimatePresence>
                {activeTab === i && (
                  <motion.div
                    initial={{ opacity: 0, y: 100, display: "none" }}
                    animate={{ opacity: 1, y: 0, display: "flex" }}
                    // exit={{ display: "none" }}
                    transition={{
                      delay: 0.3,
                      duration: 1,
                      ease: "easeOut",
                      type: "spring",
                    }}
                    className="flex flex-col items-end"
                  >
                    <h2 className="text-[16px] lg:text-[22px] font-bold">
                      {el.title}
                    </h2>
                    <h2
                      className={`${text} text-[16px] lg:text-[22px] font-bold`}
                    >
                      {el.text}
                    </h2>
                    <Link to={"/devis"}>
                      <FluchBox
                        top={bg}
                        prop={`px-[28px] py-[15px] mt-2 ${bg}`}
                        element={
                          <span className="text-[13px] font-semibold">
                            OBTENIR UN DEVIS
                          </span>
                        }
                      />
                    </Link>
                    <div className="flex items-start gap-1">
                      <FluchBox
                        click={handleBackItem}
                        top={bg}
                        prop={`p-2 hover:cursor-pointer mt-2 bg-transparent hover:bg-white/0 border border-transparent hover:border-gray-500 hover:text-white`}
                        element={<BiChevronLeft className="text-2xl" />}
                      />
                      <FluchBox
                        click={handleNextItem}
                        top={bg}
                        prop={`p-2 hover:cursor-pointer mt-2 bg-transparent hover:bg-white/0 border border-transparent hover:border-gray-500 hover:text-white`}
                        element={<BiChevronRight className="text-2xl" />}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <motion.img
              animate={{
                opacity: activeTab === i ? 1 : 0,
                x: activeTab === i ? 0 : -200,
              }}
              transition={{ bounce: 2 }}
              className="object-cover w-full h-full"
              src={el.img}
              alt={el.img}
            />
          </motion.div>
        ))}
        <div className="absolute top-0 bottom-0 right-0 left-0 bg-gray-900/80" />
      </div>
    </div>
  );
};

export default ImageCarousel;
