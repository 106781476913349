import React, { forwardRef, useContext } from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { ThemeContext } from "../../theme/ThemeContext";

const QuoteCarousel = forwardRef(({ data, idq, visible, toogle }, ref) => {
  const { bg } = useContext(ThemeContext);
  return (
    <div ref={ref} className="flex-none w-full h-full min-h-[200px] flex flex-col items-end">
      <div className="bg-[#F5F5F5] quotecarousel mt-[15px] p-[55px] text-justify leading-[22px] mb-[10px] relative text-[13px] text-slate-700 cursor-pointer">
        <div
          className={`${bg} absolute -top-5 text-lg left-[30px] text-white border-[8px] border-white p-1.5`}
        >
          <FaQuoteLeft />
        </div>
        {data.text}
      </div>
      <div
        className={`relative -top-[5px] text-lg flex gap-3 items-center right-[70px] text-slate-800 pt-10`}
      >
        <div className="w-11 h-11 rounded-full bg-[#F5F5F5] overflow-hidden">
          <img
            src={data?.picture}
            alt="profile"
            className="object-cover h-full"
          />
        </div>
        <div className="flex flex-col items-end justify-end leading-5">
          <p className="mt-[2px] p-0 mb-0 text-[14px] font-bold text-slate-700 uppercase">
            {data.by}
          </p>
          <span className="text-[10px] font-medium text-gray-400">
            {data.post}
          </span>
        </div>
      </div>
    </div>
  );
});

export default QuoteCarousel;
