import { useFormik } from "formik";
import React, { useContext } from "react";
import CustomField from "../CustomField";
import FluchBox from "../../ui/FluchBox";
import { ThemeContext } from "../../../theme/ThemeContext";
import { form1Schema } from "../validations/validateSchema";
import CustomSelect from "../CustomSelect";

const service = [
  "Déménagement",
  "Déménagement avec montage & démontage de meubles",
  "Aide au déménagement sans camion",
  "Débarras encombrants",
  "Déchèterie",
  "Démolition",
  "Livraison",
  "Manutention",
  "Transport",
];

const Form1 = ({ next, add }) => {
  const { bg } = useContext(ThemeContext);
  const localval = JSON.parse(localStorage.getItem("form1"));
  const initialValues = {
    nom: "",
    prenom: "",
    email: "",
    service: "Déménagement",
    ville_de_depart: "",
    ville_d_arrivee: "",
    date_prestation: "",
  };
  const formik = useFormik({
    initialValues: localval || initialValues,
    onSubmit: (values) => {
      add({ form1: values });
      localStorage.setItem("form1", JSON.stringify(values));
      next();
    },
    validationSchema: form1Schema,
    enableReinitialize: true,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4"
    >
      <CustomField
        error={
          formik.errors.nom && formik.touched.nom ? formik.errors.nom : null
        }
        formik={formik}
        name="nom"
        text={"Nom"}
        type="text"
        value={formik.values.nom}
      />
      <CustomField
        error={
          formik.errors.prenom && formik.touched.prenom
            ? formik.errors.prenom
            : null
        }
        formik={formik}
        name="prenom"
        text={"Prénom"}
        value={formik.values.prenom}
      />
      <CustomField
        error={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }
        formik={formik}
        name="email"
        text={"Email"}
        type="email"
        value={formik.values.email}
      />
      <CustomSelect
        name="service"
        formik={formik}
        data={service}
        label={"Service"}
        value={formik.values.service}
      />
      {/* <select>
        <option value="1">hell</option>
      </select> */}
      {/* <CustomField
        error={formik.errors.service}
        formik={formik}
        name="service"
        text={"Service"}
      /> */}
      <CustomField
        error={
          formik.errors.ville_de_depart && formik.touched.ville_de_depart
            ? formik.errors.ville_de_depart
            : null
        }
        formik={formik}
        name="ville_de_depart"
        text={"Ville de départ"}
        value={formik.values.ville_de_depart}
      />
      <CustomField
        error={
          formik.errors.ville_d_arrivee && formik.touched.ville_d_arrivee
            ? formik.errors.ville_d_arrivee
            : null
        }
        formik={formik}
        name="ville_d_arrivee"
        text={"Ville d'arrivée"}
        value={formik.values.ville_d_arrivee}
      />
      <CustomField
        error={
          formik.errors.date_prestation && formik.touched.date_prestation
            ? formik.errors.date_prestation
            : null
        }
        formik={formik}
        name="date_prestation"
        text={"Date de prestation"}
        type="date"
        value={formik.values.date_prestation}
      />
      <div className="flex justify-end items-end">
        <FluchBox
          top={bg}
          type="submit"
          prop={
            "py-[15px] px-14 border border-transparent hover:border-primary"
          }
          element={"SUIVANT"}
        />
      </div>
    </form>
  );
};

export default Form1;
