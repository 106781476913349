import { createContext, useState } from "react";
import { Color, TextColor } from "../contants/color";

export const ThemeContext = createContext();

function ThemeContextProvider({ children }) {
  const [theme, settheme] = useState("primary");
  const [color, setcolor] = useState(Color[theme]);
  const [textColor, settextColor] = useState(TextColor[theme]);

  const changeTheme = (theme) => {
    settheme(theme);
    setcolor(Color[theme]);
    settextColor(textColor[theme]);
  };

  const responsiveMargin = "mx-[40px] lg:mx-[100px] xl:mx-[175px] px-[5px] lg:px-[15px]";

  return (
    <ThemeContext.Provider
      value={{ bg: color, text: textColor, ct: responsiveMargin }}
    >
      <button hidden onClick={changeTheme}>
        click me
      </button>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
