import React from "react";
import { AiFillMail } from "react-icons/ai";
import { FaAddressBook, FaLocationPin, FaPhone } from "react-icons/fa6";

const Icons = {
  phone: <FaPhone />,
  email: <AiFillMail />,
  adresse: <FaAddressBook />,
  location: <FaLocationPin />,
};

const AdressList = ({ data }) => {
  return (
    <ul className="space-y-3 text-[14px] text-gray-200">
      {data.map(({ text, Icon }, i) => (
        <li className="flex items-center space-x-2" key={i}>
          {Icons[Icon]}
          <span>{text}</span>
        </li>
      ))}
    </ul>
  );
};

export default AdressList;
