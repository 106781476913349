import React from "react";

const CustomRadio = ({
  col,
  label,
  data,
  error,
  custom,
  value,
  formik,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-2">
      <label className="font-medium text-[16px] text-primary">{label}</label>
      <div
        className={`flex ${
          col ? "flex-col lg:flex-row" : "flex-row"
        } gap-x-6 gap-y-2 flex-wrap`}
      >
        {data.map((el, key) => (
          <label key={key} className={`flex  gap-2 items-center`}>
            <input
              {...props}
              type="radio"
              value={el}
              checked={value === el}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {el}
          </label>
        ))}
      </div>
      <p className="text-sm text-red-500">{error}</p>
    </div>
  );
};

export default CustomRadio;
