import React, { useContext } from "react";
import logo from "../../assets/logo.png";
import { ThemeContext } from "../../theme/ThemeContext";

const FootBlock = ({ Label, label, children, prop }) => {
  const { bg } = useContext(ThemeContext);
  return (
    <div className={`${prop} flex flex-col space-y-8 items-start`}>
      {Label ? (
        <img className="w-14" src={logo} alt="logo" />
      ) : (
        <p className="flex items-center space-x-4">
          <span className={`${bg} block w-[10px] h-[10px]`} />
          <span className="font-semibold text-[15px] uppercase text-white">
            {label ? label : "TITRE"}
          </span>
        </p>
      )}
      <div className={`${bg} h-[0.5px] w-28 opacity-15`} />
      {children}
    </div>
  );
};

export default FootBlock;
