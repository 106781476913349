import React from "react";

const Map = () => {
  return (
    <div className="h-[450px]">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.4658123533472!2d4.88185817617826!3d45.78189487108092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4c01869cb7da9%3A0x26778d31c92b2dac!2s26%20Rue%20du%20Luizet%2C%2069100%20Villeurbanne%2C%20France!5e0!3m2!1sen!2scm!4v1718193892824!5m2!1sen!2scm"
        width="600"
        height="450"
        // style="border:0;"
        className="w-full"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>
    </div>
  );
};

export default Map;
