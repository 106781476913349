import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import FluchBox from "../../ui/FluchBox";
import { ThemeContext } from "../../../theme/ThemeContext";
import { form2Schema } from "../validations/validateSchema";
import CustomSelect from "../CustomSelect";
import CustomRadio from "../../ui/CustomRadio";
import { EtageDep, distance, tailleAsc } from "../../../contants";
import CustomTextArea from "../CustomTextArea";

const Form3 = ({ next, back, add }) => {
  const { bg } = useContext(ThemeContext);
  const [selectedDist, setselectedDist] = useState(0);
  const localval = JSON.parse(localStorage.getItem("form3"));
  const initialValues = {
    etage_depar: "Rez-de-chaussée plain-pied",
    taille_ascenseur: "Pas d'ascenseur",
    place_parking: "",
    isreserved: "",
    distance_camion: distance[selectedDist],
  };
  const formik = useFormik({
    initialValues: localval || initialValues,
    onSubmit: (values) => {
      localStorage.setItem("form3", JSON.stringify(values));
      add({ form3: values });
      next();
    },
    validationSchema: form2Schema,
  });

  return (
    <div>
      <p className="mb-4 font-semibold text-primary text-xl">
        Informations de la prestation à l'arrivée
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-4"
      >
        <CustomSelect
          value={formik.values.etage_depar}
          name="etage_depar"
          formik={formik}
          data={EtageDep}
          label={"Étage à l'arrivée"}
        />
        <CustomSelect
          value={formik.values.taille_ascenseur}
          name="taille_ascenseur"
          formik={formik}
          data={tailleAsc}
          label={"Taille de l'ascenseur à l'arrivée"}
        />

        <div className="flex flex-col gap-1">
          <label className="font-medium text-[16px] text-primary">
            Distance entre le lieu où le camion sera garé et l’appartement à
            l'arrivée
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
            {distance.map((el, key) => (
              <div
                key={key}
                onClick={() => {
                  formik.setFieldValue("distance_camion", el);
                  setselectedDist(key);
                }}
                className={`cursor-pointer p-3 text-center hover:border-transparent ${
                  el === formik.values.distance_camion &&
                  "bg-cyan-500 text-white"
                } duration-300 hover:text-white hover:bg-cyan-500 border rounded hover:border-primary`}
              >
                {el}
              </div>
            ))}
          </div>
        </div>
        <div>
          <CustomRadio
            value={formik.values.place_parking}
            data={["Oui", "Non"]}
            error={
              formik.errors.place_parking && formik.touched.place_parking
                ? formik.errors.place_parking
                : null
            }
            name="place_parking"
            formik={formik}
            label={"Place de parking à l'arrivée"}
          />
          {formik.values.place_parking === "Non" && (
            <CustomTextArea
              error={
                formik.errors.isreserved && formik.touched.isreserved
                  ? formik.errors.isreserved
                  : null
              }
              value={formik.values.isreserved}
              name="isreserved"
              formik={formik}
              text={
                "Allez-vous réserver une place soit auprès de la mairie, soit à l'aide de 2 véhicules, ou autre solution?"
              }
            />
          )}
        </div>
        <div className="lg:col-span-2 flex  flex-col md:flex-row justify-start lg:justify-end gap-3 items-end">
          <FluchBox
            // top={bg}
            click={back}
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={"PRECEDENT"}
          />
          <FluchBox
            top={bg}
            type="submit"
            prop={
              "py-[15px] w-full lg:w-auto px-14 border border-transparent hover:border-primary"
            }
            element={"SUIVANT"}
          />
        </div>
      </form>
    </div>
  );
};

export default Form3;
