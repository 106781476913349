import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { motion } from "framer-motion";

const DropInfo = ({ title, text, open, handleToogle, elid }) => {
  return (
    <div
      className={`${
        open ? "bg-transparent" : "bg-[#F5F5F5] border-t-transparent"
      } border-t px-[40px] py-[22px] flex flex-col overflow-hidden duration-300`}
    >
      <div
        className="flex gap-6 justify-between items-center w-full cursor-pointer"
        onClick={() => handleToogle(elid)}
      >
        <h3 className="text-[13px] text-slate-600">{title}</h3>
        <div className="w-7 h-7 bg-slate-700 flex-none text-white text-sm font-light flex justify-center items-center">
          {open ? <FaMinus /> : <FaPlus />}
        </div>
      </div>
      <motion.p
        animate={{
          opacity: open ? 1 : 0,
          height: open ? "auto" : 0,
          marginTop: open ? "22px" : 0,
        }}
        className="text-[13px] text-slate-600"
      >
        {text}
      </motion.p>
    </div>
  );
};

export default DropInfo;
