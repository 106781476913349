import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../theme/ThemeContext";

const TitleNavigation = () => {
  const { pathname } = useLocation();
  const { text } = useContext(ThemeContext);
  const pageName = pathname.replace("/", "");

  return (
    <div>
      <h1 className="uppercase m-0 p-0 text-white text-[35px] font-bold">
        {pageName}
      </h1>
      <div className="flex mt-1 text-[12px] uppercase items-center space-x-2 justify-center text-gray-300">
        <span>Accueil</span>
        <span>/</span>
        <span className={`${text}`}>{pageName}</span>
      </div>
    </div>
  );
};

export default TitleNavigation;
