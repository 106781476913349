import React from "react";
// import SendMail from "./Elements/SendMail";
import FooterNavigation from "./Elements/FooterNavigation";
import Coyriht from "./Elements/Coyriht";

const Footer = () => {
  return (
    <footer className={``}>
      {/* <SendMail /> */}
      <FooterNavigation />
      <Coyriht />
    </footer>
  );
};

export default Footer;
