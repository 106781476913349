import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import CustomTitle from "../../components/ui/CustomTitle";
import DevisForm from "./slices/DevisForm";
// import Stepper from "./slices/Stepper";

const Devis = () => {
  const { ct } = useContext(ThemeContext);
  return (
    <div className={`${ct} py-[100px]`}>
      <div className="w-full flex flex-col">
        <CustomTitle
          title={"OBTENIR UN DEVIS"}
          text={"Nous allons revenir vers vous rapidement."}
        />
      </div>
      <DevisForm />
    </div>
  );
};

export default Devis;
