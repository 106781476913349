import React, { useContext, useState } from "react";
import { ThemeContext } from "../../theme/ThemeContext";
import data from "../../contants/texts.json";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import { MdPhone } from "react-icons/md";
import FluchBox from "./FluchBox";
import { BiMenu } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { motion, AnimatePresence } from "framer-motion";

const NavBar = () => {
  const [isToggled, setToggle] = useState(false);

  const navContainer = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
    hidden: {
      x: -250,
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3,
      },
    },
  };

  const navList = {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.07,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const navItem = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  const texts = data.navBar;
  const { pathname } = useLocation();
  const { ct, text, bg } = useContext(ThemeContext);

  return (
    <nav
      className={`${ct} absolute left-0 right-0 top-0 py-[45px] z-40 flex justify-between items-center`}
    >
      <img src={logo} alt="logo" className="w-16" />
      <div className="flex peer items-center space-x-2 lg:space-x-0">
        <ul className="text-white hidden lg:block text-[13px] font-bold divide-x divide-gray-600 uppercase">
          {texts.links.map((el, key) => (
            <NavLink
              className={({ isActive }) =>
                `${
                  (isActive || (pathname === "/" && key === 0)) && text
                } px-[32px]`
              }
              to={el.path}
              key={key}
            >
              {el.text}
            </NavLink>
          ))}
        </ul>
        <FluchBox
          click={() => setToggle(true)}
          prop={"py-2 block lg:hidden px-2 rounded bg-white"}
          top={bg}
          size
          element={<BiMenu />}
        />
        <a href="tel:+33749940585" target="_blank" rel="noreferrer">
          <FluchBox
            prop={"py-2 px-2 rounded bg-white"}
            top={bg}
            size
            element={<MdPhone />}
          />
        </a>
      </div>
      <AnimatePresence>
        {isToggled && (
          <motion.div
            initial="hidden"
            animate={isToggled ? "visible" : "hidden"}
            exit="hidden"
            variants={navContainer}
            onClick={() => {
              setToggle(false);
            }}
            className="fixed lg:hidden top-0 h-screen bottom-0 z-[100] right-0 left-0 bg-gray-800/40"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="h-full bg-white w-[290px] flex flex-col items-center gap-8 pt-14"
            >
              <ImCross
                className={`${text} font-black cursor-pointer hover:text-secondary duration-300`}
                onClick={() => setToggle(false)}
              />
              <motion.ul
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={navList}
                className="text-black px-4 w-full flex border-none flex-col text-[15px] font-light divide-y divide-gray-200 uppercase"
              >
                {texts.links.map((el, key) => (
                  <motion.li className="py-3" variants={navItem} key={key}>
                    <NavLink
                      onClick={() => setToggle(false)}
                      className={({ isActive }) =>
                        `${
                          (isActive || (pathname === "/" && key === 0)) && text
                        } border-slate-600 px-[20px]`
                      }
                      to={el.path}
                    >
                      {el.text}
                    </NavLink>
                  </motion.li>
                ))}
              </motion.ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default NavBar;
