import axios from "axios";
import { useState } from "react";
import { BASE_URL } from "../contants";

const UseSendEmail = () => {
  const [isLoading, setisLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [data, setdata] = useState(false);

  const sendEmail = (data) => {
    setisLoading(true);
    axios
      .post(`${BASE_URL}messageUser/sendMessage`, data)
      .then((response) => {
        setisLoading(false);
        setdata(response);
        setisSuccess(true);
      })
      .catch((error) => {
        setisLoading(false);
        seterror(error);
      });
  };

  return { data, isLoading, error, sendEmail, isSuccess };
};

export default UseSendEmail;
