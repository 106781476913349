export const Color = {
  primary: "bg-[#00c0e2]",
  secondary: "bg-[#46b971]",
  tertiary: "bg-[#f14b4b]",
};

export const TextColor = {
  primary: "text-[#00c0e2]",
  secondary: "text-[#46b971]",
  tertiary: "text-[#f14b4b]",
};
