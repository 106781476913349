import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";

const ContactBox = ({ data }) => {
  const { text, bg } = useContext(ThemeContext);
  return (
    <div
      className={`flex relative cust flex-col gap-[25px] items-center justify-center py-[100px] ${
        !data.theme && "bg-primary"
      }`}
    >
      <span className={`${data.theme ? "text-white" : text} text-4xl`}>
        {data.icon}
      </span>
      <h2 className={`text-[17px] font-bold text-white`}>{data.title}</h2>
      <div className={`${data.theme ? "bg-white" : bg} h-[2.5px] w-6`} />
      <p
        className={`${
          data.spec ? "max-w-[40%]" : "max-w-[50%]"
        } text-[13px] font-medium text-white text-center`}
      >
        {data.text}
      </p>
    </div>
  );
};

export default ContactBox;
