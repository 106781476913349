import React, { useContext } from "react";
import { ThemeContext } from "../../../theme/ThemeContext";
import ContactBox from "../../../components/ui/ContactBox";
import { MdPhone } from "react-icons/md";
import { FaClock, FaLocationPinLock } from "react-icons/fa6";

const data = [
  {
    icon: <FaLocationPinLock />,
    title: "EMPLACEMENT",
    text: "26 Rue du Luizet, 69100 Villeurbanne, France",
    theme: true,
  },
  {
    icon: <MdPhone />,
    title: "TÉLÉPHONE | EMAIL",
    text: "+33 749940585 | 758398933 djibrilservice1@gmail.com​",
    theme: false,
  },
  {
    icon: <FaClock />,
    title: "HEURES D'OUVERTURE",
    text: "Lun - Ven : 08.00 19.00 Sam : 08.00 18.00",
    theme: true,
    spec: true,
  },
];

const Contactblock2 = () => {
  const { bg } = useContext(ThemeContext);
  return (
    <div className={`${bg}`}>
      <div
        className={`mx-[0px] lg:mx-[100px] xl:mx-[175px] px-[0px] lg:px-[15px] grid grid-cols-1 md:grid-cols-3`}
      >
        {data.map((el, key) => (
          <ContactBox key={key} data={el} />
        ))}
      </div>
    </div>
  );
};

export default Contactblock2;
