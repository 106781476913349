import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../theme/ThemeContext";
import CustomTitle from "../../../components/ui/CustomTitle";
import DropInfo from "../../../components/ui/DropInfo";
import QuoteCarousel from "../../../components/ui/QuoteCarousel";
import { AnimatePresence, motion } from "framer-motion";
import FluchBox from "../../../components/ui/FluchBox";
const MotionQuote = motion(QuoteCarousel);

const data2 = [
  {
    text: "Merci à Djibril et son équipe ! Réactifs, ponctuels, motivés et de bonne humeur. Nous recommandons et ne manquerons pas de faire la pub autour de nous !",
    by: "Jerome Vastel",
    post: "Guide local",
    picture:
      "https://lh3.googleusercontent.com/a-/ALV-UjVaj8nZscGdfSq7gDQ_-_dB_aPWJZuF1W48fsO4WYqkJhnFoLMp=w75-h75-p-rp-mo-ba3-br100",
  },
  {
    text: "Très réactif et à l’écoute. Tout a été très clair depuis le début. Les conditions et le paiement. Toujours joignables et la veille de la prestation tu reçois une confirmation. Parfait.",
    by: "Javier F. Mingote",
    post: "Guide locale",
    picture:
      "https://lh3.googleusercontent.com/a-/ALV-UjUrTvXXdkVtSOhL2mA_P8--XiGe4Nrv3O9SRxy0oaQJpva5M_1x=w75-h75-p-rp-mo-br100",
  },
  {
    text: "Très bonne communication & prise de contact. Réactifs, sérieux, ponctuels & sympas. Parfait 👍",
    by: "Joan renaud",
    post: "Guide locale",
    picture:
      "https://lh3.googleusercontent.com/a-/ALV-UjXTPnWjUqhn3l8jGLT2fWq2_US7qct_Uhw22cEH5nQUwC59ubSv=w75-h75-p-rp-mo-br100",
  },
];

const data = [
  {
    title: "Des services professionnels pour tous vos de déménagements",
    text: "Nous proposons une large gamme de services professionnels pour tous vos besoins de déménagement, que ce soit résidentiel, commercial ou industriel. Nos équipes qualifiées manient avec soin vos meubles et objets de valeur.",
  },
  {
    title: "Une équipe et des matériaux de qualité",
    text: "Nous utilisons des matériaux haut de gamme pour emballer et protéger vos biens. Nous assurons également le démontage et remontage de vos meubles pour faciliter votre déménagement.",
  },
  {
    title: "Transports sécurisés",
    text: "Nos camions spacieux et bien entretenus, conduits par des professionnels expérimentés, garantissent un transport sûr et dans les délais de vos meubles.",
  },
];

const Testimonials = () => {
  const { ct, bg } = useContext(ThemeContext);
  const [activeDrop, setactiveDrop] = useState(null);
  const [activeQuote, setactiveQuote] = useState(0);
  const handleToogle = (key) => {
    const newValue = activeDrop === key ? null : key;
    setactiveDrop(newValue);
  };
  const handleToogleQuote = (key) => {
    // const newValue = activeDrop === key ? null : key;
    setactiveQuote(key);
  };
  return (
    <div className={`${ct} py-[100px]`}>
      <div className="w-full flex flex-col lg:flex-row items-start gap-x-10 gap-y-20">
        <div className="flex-1 relative flex flex-col">
          <CustomTitle title={"TÉMOIGNAGES"} text={"Des clients satisfaits"} />
          <div className="overflow-hidden relative h-full w-full">
            <div className="flex flex-row overflow-hidden">
              {data2.map((el, i) => (
                <AnimatePresence key={i}>
                  {activeQuote === i && (
                    <MotionQuote
                      // variants={quotevariants}
                      initial={{ display: "none", translateX: `100%` }}
                      animate={{ display: "flex", translateX: 0 }}
                      data={el}
                    />
                  )}
                </AnimatePresence>
              ))}
            </div>
            <div className="absolute bottom-6 left-10 flex space-x-2">
              {Array.from({ length: data2.length }, (_, i) => (
                <div
                  key={i}
                  onClick={() => handleToogleQuote(i)}
                  className={`w-2 h-2 rounded-full duration-300 ${
                    activeQuote === i
                      ? "bg-neutral-500 scale-x-95"
                      : "bg-neutral-300"
                  }`}
                />
              ))}
            </div>
          </div>
          <a
            className="self-center mt-8 lg:hidden"
            href="https://www.google.com/localservices/prolist?g2lbs=AOHF13knQc87DNfET8yrioRApUVc64i7n1-8p96aOHR84tLICyBjI-6g6zlyOlF1ngUxHC9fpJC1o0KPY7hsdOicdYa_bRhFspQ1oMRgbSdRzeuDlTEcqsg%3D&hl=en-CM&gl=cm&cs=1&ssta=1&q=djib%20demenagement&oq=djib%20demenagement&src=2&spp=Cg0vZy8xMXZ5cTc2ZDZqOtgBV2g4UUFCQUJHQUFZQVNJUlpHcHBZaUJrWlcxbGJtRm5aVzFsYm5ReUFtWnlrZ0VPYlc5MmFXNW5YMk52YlhCaGJubWFBU05EYUZwRVUxVm9UazFIT1c1VE1GWktVVEJHYmxOVlVrVk9NRGsxVkd0YU0wVkJSYW9CUmdvS0wyMHZNRFF6Y25oNU1CQUJNaDhRQVNJYnhzRHprd09xdzlOOXVIZl9TUHNJdklrNFYxWGdwZ2RfaWtESE1oVVFBaUlSWkdwcFlpQmtaVzFsYm1GblpXMWxiblE9&serdesk=1&lrlstt=1719430897785&ved=2ahUKEwiJxJecgvqGAxUFU0EAHa4sDOYQvS56BAgaEAE&slp=MgBAAVIECAIgAGAAaAE%3D&scp=ChNnY2lkOm1vdmluZ19jb21wYW55EiAiCG1hcCBhcmVhKhQNDU-j-xWv66kBHd7-SCsl_ug_LRoRZGppYiBkZW1lbmFnZW1lbnQqEFJlbW92YWxzIGNvbXBhbnk%3D#ts=3"
            target="_blank"
            rel="noreferrer"
          >
            <FluchBox
              prop={
                "px-[60px] py-[15px] border border-white hover:border-primary"
              }
              top={bg}
              element={
                <span className="text-[12px] font-medium">
                  VOIR LES AVIS GOOGLE
                </span>
              }
            />
          </a>
        </div>
        <div className="flex-1 w-full flex flex-col">
          <CustomTitle
            title={"POURQUOI NOUS CHOISIR"}
            text={"Déménagez l'esprit tranquille"}
          />
          <div className="flex flex-col space-y-[2px]">
            {data.map((el, key) => (
              <DropInfo
                open={activeDrop === key}
                key={key}
                text={el.text}
                title={el.title}
                elid={key}
                handleToogle={handleToogle}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
