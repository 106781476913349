export const EtageDep = [
  "Rez-de-chaussée plain-pied",
  "Accès au rez-de-chaussée via un escalier",
  "Garde Meubles",
  "Garage - Cave - Sous sol",
  "1er étage",
  "2e étage",
  "3e étage",
  "4e étage",
  "5e étage",
  "6e étage",
  "7e étage",
  "8e étage",
  "9e étage",
  "10e étage",
];

export const tailleAsc = [
  "Pas d'ascenseur",
  "Ascenseur 2 personnes",
  "Ascenseur 3 personnes",
  "Ascenseur 4 personnes",
  "Ascenseur 5 personnes",
  "Ascenseur 6 personnes",
  "Ascenseur 7 personnes",
  "Ascenseur 8 personnes",
  "Ascenseur 9 personnes",
  "Ascenseur 10 personnes",
];

export const distance = [
  "moins de 10 mètres",
  "entre 10 et 20 mètres",
  "entre 20 et 30 mètres",
  "plus de 30 mètres",
];

const BACK_OFFICE_URLS = {
  LOCAL: "http://localhost:4001",
  ONLINE: "https://api.vosdemenageurs.fr",
};



export const BASE_URL = BACK_OFFICE_URLS.ONLINE;
