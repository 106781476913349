import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeContext";

const CustomTitle = ({ overide, title, text }) => {
  const { bg } = useContext(ThemeContext);
  return (
    <div className="flex flex-col items-center mb-12">
      <h2
        className={`text-[24px] font-bold ${
          overide ? "text-white" : "text-primary"
        } text-center`}
      >
        {title}
      </h2>
      <p className="text-[12px] text-center flex gap-4 items-center">
        <span className={`${overide ? "bg-white" : bg} block h-2 w-2`} />
        <span className={`${overide ? "text-white" : "text-primary"} uppercase`}>
          {text}
        </span>
      </p>
    </div>
  );
};

export default CustomTitle;
