import React from "react";
import NavBar from "../../../components/ui/NavBar";
import TitleNavigation from "../../../components/ui/TitleNavigation";

const Header = () => {
  return (
    <div className="bg-secondary relative">
      <div className="absolute top-0 bottom-0 left-0 right-0 overflow-hidden">
        <img
          src={
            "https://img.freepik.com/free-photo/happy-manual-worker-using-touchpad-while-communicating-with-his-coworker-organizing-package-delivery_637285-2233.jpg?w=1060&t=st=1717675277~exp=1717675877~hmac=225050e9703f7918309ae9c1886e54fee7a064162da97368c5c4bd43819c5890"
          }
          className="object-contain w-full opacity-10 "
          alt=""
        />
      </div>
      <NavBar />
      <div className="pt-[130px] pb-[60px] relative flex justify-center">
        <TitleNavigation />
      </div>
    </div>
  );
};

export default Header;
