import React, { useState } from "react";
import Form1 from "../../../components/forms/deviformulaires/Form1";
import Form2 from "../../../components/forms/deviformulaires/Form2";
import Stepper from "./Stepper";
import Form3 from "../../../components/forms/deviformulaires/Form3";
import Form4 from "../../../components/forms/deviformulaires/Form4";
import Form5 from "../../../components/forms/deviformulaires/Form5";

const Forms = [Form1, Form2, Form3, Form4, Form5];

const DevisForm = () => {
  const [activeForm, setactiveForm] = useState(0);

  const handleAddValForm = (val) => {
    const finalLocal = JSON.parse(localStorage.getItem("form"));
    localStorage.setItem("form", JSON.stringify({ ...finalLocal, ...val }));
  };

  const handleNext = () => {
    setactiveForm(activeForm + 1);
  };
  const handleBack = () => {
    setactiveForm(activeForm - 1);
  };
  const handleReset = () => {
    setactiveForm(activeForm + 1);
    setTimeout(() => {
      localStorage.clear();
      // window.location.reload();
    }, 2000);
  };
  return (
    <div>
      {activeForm < 5 && <Stepper step={activeForm} max={Forms.length} />}
      {Forms.map(
        (Form, key) =>
          activeForm === key && (
            <Form
              key={key}
              next={handleNext}
              back={handleBack}
              add={handleAddValForm}
              reset={handleReset}
            />
          )
      )}
      {activeForm === 5 && (
        <p className="text-center text-primary w-full">
          Merci de nous avoir contacté ! Nous allons revenir vers vous
          rapidement.
        </p>
      )}
    </div>
  );
};

export default DevisForm;
