import { BrowserRouter } from "react-router-dom";
import NavigationScroll from "./layout/MainLayout/NavigationScroll";
import Routes from "./routes";
import ThemeContextProvider from "./theme/ThemeContext";
function App() {
  return (
    <BrowserRouter>
      <ThemeContextProvider>
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeContextProvider>
    </BrowserRouter>
  );
}

export default App;
