import * as Yup from "yup";

export const form1Schema = Yup.object().shape({
  // nom: Yup
  //   .string("Entrez une chaine de caractère")
  //   .max(35, "Ne dois pas dépasser 25 charactères")
  //   .required("Ce champ est obligatoire"),
  prenom: Yup.string("Entrez une chaine de caractère")
    .max(35, "Ne dois pas dépasser 25 charactères")
    .required("Ce champ est obligatoire"),
  email: Yup.string("Entrez une chaine de caractère")
    .email("Entrez une adresse email valide")
    .required("Ce champ est obligatoire"),
  ville_de_depart: Yup.string("Entrez une chaine de caractère").required(
    "Ce champ est obligatoire"
  ),
  ville_d_arrivee: Yup.string("Entrez une chaine de caractère").required(
    "Ce champ est obligatoire"
  ),
  date_prestation: Yup.date("Entrez une date valide").required(
    "Ce champ est obligatoire"
  ),
});

export const form2Schema = Yup.object().shape({
  isreserved: Yup.string().when("place_parking", {
    is: "Non",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  place_parking: Yup.string().required("Ce champ est obligatoire"),
});

export const form4Schema = Yup.object().shape({
  precision: Yup.string().when("meuble_dem_rem", {
    is: "Oui",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentrepasdep: Yup.string().when("dep", {
    is: "Non",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentrepasarr: Yup.string().when("arr", {
    is: "Non",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  liste_demen: Yup.string().required("Ce champ est obligatoire"),
  meuble_dem_rem: Yup.string().required("Ce champ est obligatoire"),
  dep: Yup.string().required("Ce champ est obligatoire"),
  arr: Yup.string().required("Ce champ est obligatoire"),
});

export const form5Schema = Yup.object().shape({
  range: Yup.number()
    .min(1, "La distance doit être supérieure à 1 km")
    .required("Ce champ est obligatoire"),
  montantconvtarif: Yup.number("Entrez un nombre valide").when("convtarif", {
    is: "Oui",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  montantacompte: Yup.number("Entrez un nombre valide").when("acompte", {
    is: "Oui",
    then: (schema) => schema.required("Ce champ est obligatoire"),
    otherwise: (schema) => schema.notRequired(),
  }),
  convtarif: Yup.string().required("Ce champ est obligatoire"),
  acompte: Yup.string().required("Ce champ est obligatoire"),
});
