import React from "react";

const FluchBox = ({ top, prop, element, size, click, ...props }) => {
  return (
    <button
      {...props}
      onClick={click || null}
      className={` hover:bg-white cursor-pointer hover:text-black group relative text-[10px] font-bold text-white duration-500 ${prop}`}
    >
      <div className="z-20 absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col">
        <span
          className={`${
            top ? top : "bg-slate-800"
          } group-hover:translate-x-[105%] flex-1 duration-500`}
        />
        <span
          className={`${
            top ? top : "bg-slate-800"
          } group-hover:-translate-x-[105%] flex-1 duration-500`}
        />
      </div>
      <span
        className={`relative z-50  ${size ? `text-[16px]` : `text-[11px]`} `}
      >
        {element}
      </span>
    </button>
  );
};

export default FluchBox;
