import React from "react";

const CustomInput = (props) => {
  return (
    <div className={`${props.area ? "lg:col-span-2" : ""}`}>
      {props.area ? (
        <textarea
          {...props}
          className="focus:outline-none lg:col-span-2 border-b bg-transparent border-b-gray-300 placeholder:italic placeholder:text-primary italic text-[13px] font-medium text-primary w-full px-[20px] py-[18px]"
        />
      ) : (
        <input
          {...props}
          className="focus:outline-none border-b bg-transparent border-b-gray-300 placeholder:italic placeholder:text-primary italic text-[13px] font-medium text-primary w-full px-[20px] py-[18px]"
        />
      )}
      <p className="text-[13px] mt-2 text-red-500">{props.error}</p>
    </div>
  );
};

export default CustomInput;
