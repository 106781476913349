import { useId } from "react";

const CustomSelect = ({ label, data, formik, ...props }) => {
  const id = useId();
  return (
    <div className="flex flex-col gap-1">
      <label className="font-medium text-[16px] text-primary" htmlFor={id}>
        {label}
      </label>
      <select
        id={id}
        {...props}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        className={`focus:outline-none border ring-1 ring-transparent ${"focus:ring-cyan-300"} rounded p-[12px] border-primary/20 hover:border-primary/80 duration-300`}
      >
        {data.map((el, key) => (
          <option key={key} value={el}>
            {el}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
