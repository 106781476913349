import React, { useId } from "react";

const CustomField = ({ text, formik, error, ...props }) => {
  const id = useId();
  return (
    <div className="flex flex-col gap-1">
      <label className="font-medium text-[16px] text-primary" htmlFor={id}>
        {text}
      </label>
      <input
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        id={id}
        {...props}
        className={`focus:outline-none border ring-1 ring-transparent ${
          error ? "focus:ring-red-300" : "focus:ring-cyan-300"
        } rounded p-[12px] border-primary/20 hover:border-primary/80 duration-300`}
      />
      <p className="text-sm text-red-500">{error}</p>
    </div>
  );
};

export default CustomField;
